<template>
  <div class="content">
    <div class="content__party">
      <h1 class="title title--big title--color">Действующие карты</h1>
      <div class="search">
        <v-select
          @input="searchClick"
          v-model="searchResult"
          :filterable="false"
          :options="searchOptions"
          @search="onSearch"
          :get-option-label="getLabel"
          placeholder="Поиск по картам (ФИО / Номер)"
          class="select"
        >
          <template slot="open-indicator">
            <svg class="select__open-indicator" xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                 fill="none">
              <path clip-rule="evenodd" d="M10.06 17.25a7.19 7.19 0 100-14.38 7.19 7.19 0 000 14.38z"
                    stroke="#C7CFDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              <path d="M20.12 20.12l-4.98-4.98" stroke="#C7CFDD" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round"></path>
            </svg>
          </template>
          <template slot="no-options">Введите свой запрос для поиска</template>
          <template slot="option" slot-scope="option">
            <div class="select__item d-center">
              {{ option.lastname }} {{ option.firstname }} {{ option.middlename }} (ID: {{ option.id }}) ({{ option.number }})
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.lastname }} {{ option.firstname }} {{ option.middlename }} (ID: {{ option.id }}) ({{ option.number }})
            </div>
          </template>
        </v-select>
      </div>
    </div>
    <div class="content__party">
      <div class="table-limiter">
        <div class="table-limiter__text">Количество записей на странице:</div>
        <div class="table-limiter__select">
          <dropdown class="my-dropdown-toggle"
            :options="limitOptions"
            :selected="limitSelected"
            :closeOnOutsideClick="true"
            @updateOption="updateLimiter"
          >
          </dropdown>
        </div>
      </div>
      <div class="table-limiter">
        <div class="table-limiter__text">
          Показаны записи <span>{{ returnEnd() > 0 ? returnStart() : 0 }} - {{ returnEnd() }}</span> из <span>{{ pagination.count }}</span>
        </div>
      </div>
    </div>
    <v-client-table @row-click="rowClick" :data="table.cards" :columns="table.columns" :options="table.options" ref="cardsTable" class="table-default table-default--dynamic">
      <div slot="lastname" slot-scope="props">
        {{ props.row.lastname }} {{ props.row.firstname }}
      </div>
      <div slot="type" slot-scope="props">
        {{ props.row.type !== null ?  props.row.type.name : '-'}}
      </div>
      <div slot="is_send_eyca" slot-scope="props">
        {{ props.row.is_send_eyca ? 'Да' : 'Нет' }}
      </div>
    </v-client-table>
    <paginate
      v-if="pagination.count > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
    <SidebarRight
      title="Информация о карте"
      :class="{'sidebar-manage--full': showSidebar}"
      @close-sidebar="showSidebar = !showSidebar"
    >
      <div class="sidebar-card">
        <div class="sidebar-card__top">
          <div class="sidebar-card__photo">
            <img :src="dataSidebar.crop" alt="" v-if="dataSidebar.crop">
            <img src="@/assets/img/no-photo.jpg" alt="" v-else>
          </div>
          <div class="sidebar-card__main-info">
            <div class="sidebar-card__item">
              <b>Владелец карты</b>
              <br>
              {{ dataSidebar.lastname }} {{ dataSidebar.firstname }}
              <br>
              {{ dataSidebar.lastname_en }} {{ dataSidebar.firstname_en }}
            </div>
            <div class="sidebar-card__item">
              <b>Дата создания</b>
              <br>
              <span>{{ dataSidebar.created_at }}</span>
            </div>
            <div class="sidebar-card__item">
              <b>Дата выпуска</b>
              <br>
              <span>{{ dataSidebar.issued_date }}</span>
            </div>
          </div>
        </div>
        <div class="sidebar-card__bottom">
          <div class="sidebar-card__item sidebar-card__item--between">
            <b>Номер карты</b> <span>{{ dataSidebar.number }}</span>
          </div>
          <div class="sidebar-card__item sidebar-card__item--between">
            <b>Действует до</b> <span>{{ dataSidebar.expired_date }}</span>
          </div>
          <div class="sidebar-card__item sidebar-card__item--between">
            <b>Тип карты</b> <span>{{ dataSidebar.type ? dataSidebar.type.name : '-' }}</span>
          </div>
          <div class="sidebar-card__item sidebar-card__item--between">
            <b>Отправлено в EYCA</b> <span>{{ dataSidebar.is_send_eyca ? 'да' : 'нет' }}</span>
          </div>
          <div class="sidebar-card__item sidebar-card__item--between" v-if="dataSidebar.eyca_result !== null">
            <b>Результат ответа EYCA</b> <span>{{ dataSidebar.eyca_result }}</span>
          </div>
          <div class="sidebar-card__item sidebar-card__item--between">
            <b>Статус</b> <span>{{ dataSidebar.is_active ? 'активная' : 'неактивная' }}</span>
          </div>
        </div>
      </div>
    </SidebarRight>
  </div>
</template>

<script>
import dropdown from 'vue-dropdowns';
import SidebarRight from '@/components/SidebarRight';
import { debounce } from 'lodash';

export default {
  name: 'Cards',
  components: { dropdown, SidebarRight },
  data() {
    return {
      table: {
        cards: [],
        columns: ['id', 'number', 'type', 'issued_date', 'expired_date', 'lastname', 'is_send_eyca'],
        options: {
          headings: {
            id: 'ID',
            number: 'Номер',
            type: 'Тип',
            issued_date: 'Дата выпуска',
            expired_date: 'Действительна до',
            lastname: 'Владелец',
            is_send_eyca: 'EYCA'
          },
          pagination: { show: false },
          sortable: [],
          perPage: 20,
          texts: {
            filter: '',
            filterPlaceholder: 'Поиск по таблице',
            noResults: 'Пока ничего нет',
            filterBy: '',
            loading: 'Загрузка',
            count: '',
            limit: 'Количество записей на странице:',
            page: 'Страница:'
          },
          rowClassCallback() {
            return 'table-default__row';
          },
          rowAttributesCallback(row) {
            return { 'data-id': row.id };
          }
        },
      },
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
      limitSelected: { name: this.$store.state.admin.tableLimit },
      limitOptions: [
        { name: 20 },
        { name: 40 },
        { name: 60 },
        { name: 80 },
        { name: 100 },
      ],
      showSidebar: false,
      dataSidebar: {},
      searchResult: null,
      searchOptions: [],
    };
  },
  mounted() {
    this.limitSelected.name = 20;
    this.fetchCards(1, this.limitSelected.name);
  },
  methods: {
    fetchCards(page = 1, limit) {
      this.$store.dispatch('admin/cards/GET_LIST', { page, limit })
        .then(response => {
          this.table.cards = response.data.results;
          this.pagination.pages = response.data.pages;
          this.pagination.count = response.data.count;
        })
        .catch(response => {
          for (const key in response.data) {
            if (typeof response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                text: response.data[key]
              });
            } else {
              this.$notify({
                type: 'error',
                text: response.data[key][0]
              });
            }
          }
        });
    },
    updateLimiter(data) {
      this.$store.commit('admin/changeTableLimit', data.name);
      this.$refs.cardsTable.setLimit(this.$store.state.admin.tableLimit);
      this.fetchCards(1, this.$store.state.admin.tableLimit);
    },
    clickPaginationCallback(page) {
      this.fetchCards(page, this.$store.state.admin.tableLimit);
    },
    returnStart() {
      return (this.pagination.page * this.table.cards.length) - (this.table.cards.length - 1);
    },
    returnEnd() {
      return this.returnStart() + this.table.cards.length - 1;
    },
    rowClick(row) {
      this.showSidebar = true;
      this.dataSidebar = row.row;
    },
    getLabel (option) {
      if (typeof option === 'object') {
        if (Object.prototype.hasOwnProperty.call(!option, this.label)) {
          return console.warn(
            `[vue-select warn]: Label key "option.${this.label}" does not` +
            ` exist in options object ${JSON.stringify(option)}.\n` +
            'https://vue-select.org/api/props.html#getoptionlabel'
          );
        }
        return option[this.label];
      }
      return option;
    },
    searchClick() {
      this.showSidebar = true;
      this.dataSidebar = this.searchResult;
    },
    onSearch (search, loading) {
      loading(true);
      this.searchDelivery(loading, search, this);
    },
    searchDelivery: debounce((loading, search, vm) => {
      vm.$store.dispatch('admin/cards/GET_QUERY', search).then(response => {
        vm.searchOptions = response.data.results;
        loading(false);
      });
    }, 350),
  }
};
</script>
